@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import '../node_modules/balloon-css/balloon.min.css';

html.hidden-overflow {
  overflow: hidden;
}

body {
  hyphens: auto;
  -webkit-hyphenate-character: '-';
}

/** Disable scroll on body when a dialog/modal is open.
  * Not supported in Firefox as of 2023-06-13 (https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility)
  */
body:has(dialog.dialog[role='dialog'][open]) {
  overflow: hidden;
}

::selection {
  background: #deedea; /* teal100 */
  color: #1a1919; /* gray1100 */
}

#elm-isolation,
#elm-isolation > div,
#elm-isolation > div > div,
#elm {
  height: 100%;
}

@font-face {
  font-family: 'SangBleuRepublic';
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url('./Font/SangBleuRepublic-Book.otf') format('opentype');
}

@font-face {
  font-family: 'SangBleuRepublic';
  font-display: swap;
  font-style: italic;
  font-weight: normal;
  src: url('./Font/SangBleuRepublic-BookItalic.otf') format('opentype');
}

@font-face {
  font-family: 'SangBleuRepublic';
  font-display: swap;
  font-style: normal;
  font-weight: 450;
  src: url('./Font/SangBleuRepublic-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SangBleuRepublic';
  font-display: swap;
  font-style: italic;
  font-weight: 450;
  src: url('./Font/SangBleuRepublic-RegularItalic.otf') format('opentype');
}

@font-face {
  font-family: 'SangBleuRepublic';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url('./Font/SangBleuRepublic-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SangBleuRepublic';
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: url('./Font/SangBleuRepublic-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'SangBleuRepublic';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('./Font/SangBleuRepublic-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SangBleuRepublic';
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: url('./Font/SangBleuRepublic-BoldItalic.otf') format('opentype');
}

/*  Responsive heading font sizes

    PS! Only using h1 isn't a strong enough selector,
    but using h1.h1, etc. works
*/
h1.hero,
a.hero {
  font-size: 40px;
  letter-spacing: -0.008em;
}
h1.h1,
a.h1 {
  font-size: 32px;
  letter-spacing: -0.008em;
}
h1.t1,
a.t1 {
  font-size: 32px;
}
h2.h2,
a.h2 {
  font-size: 28px;
  letter-spacing: -0.008em;
}
h2.t2,
a.t2 {
  font-size: 28px;
}
h3.h3,
a.h3 {
  font-size: 22px;
}
h3.t3,
a.t3 {
  font-size: 22px;
}
h4.h4,
a.h4 {
  font-size: 20px;
}
h4.t4,
a.t4 {
  font-size: 20px;
}
h5.h5,
a.h5 {
  font-size: 18px;
}
h6.h6,
a.h6 {
  font-size: 16px;
}
@media (min-width: 768px) {
  h1.hero,
  a.hero {
    font-size: 48px;
  }
  h1.h1,
  a.h1 {
    font-size: 34px;
  }
  h1.t1,
  a.t1 {
    font-size: 34px;
  }
  h2.h2,
  a.h2 {
    font-size: 30px;
  }
  h2.t2,
  a.t2 {
    font-size: 30px;
  }
  h3.h3,
  a.h3 {
    font-size: 24px;
  }
  h3.t3,
  a.t3 {
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  h1.hero,
  a.hero {
    font-size: 57px;
  }
  h1.h1,
  a.h1 {
    font-size: 36px;
  }
  h1.t1,
  a.t1 {
    font-size: 36px;
  }
  h2.h2,
  a.h2 {
    font-size: 32px;
  }
  h2.t2,
  a.t2 {
    font-size: 32px;
  }
  h3.h3,
  a.h3 {
    font-size: 26px;
  }
  h3.t3,
  a.t3 {
    font-size: 26px;
  }
}

/*  Responsive ingress font sizes */
.ingress-large {
  font-size: 18px !important;
}
.ingress-medium {
  font-size: 16px !important;
}
.ingress-small {
  font-size: 14px !important;
}
@media (min-width: 768px) {
  .ingress-large {
    font-size: 20px !important;
  }
  .ingress-medium {
    font-size: 18px !important;
  }
  .ingress-small {
    font-size: 16px !important;
  }
}

a.underline-animation:not(.no-underline-animation) .t,
[role='button'].underline-animation:not(.no-underline-animation) .t,
.markdown a {
  text-decoration: none;
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(currentColor, currentColor);
  background-size: 100% 1.5px;
  background-position: 0 100%;
  background-repeat: no-repeat;
}

a.underline-animation:not(.no-underline-animation):hover .t,
[role='button'].underline-animation:not(.no-underline-animation):hover .t,
.markdown a:hover,
a.underline-animation:not(.no-underline-animation):focus .t,
[role='button'].underline-animation:not(.no-underline-animation):focus .t,
.markdown a:focus {
  animation-name: link-underline-animation;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
}

a.hide-initial-link-underline:not([aria-selected='true']) .t,
.hide-initial-link-underline a.underline-animation:not([aria-selected='true']) .t,
.hide-initial-link-underline [role='button'].underline-animation:not([aria-selected='true']) .t {
  background-size: 0 1.5px;
}

.no-underline-animation .t {
  animation-name: none !important;
  background-size: 0 1.5px !important;
}

@keyframes link-underline-animation {
  0% {
    background-size: 0% 1.5px;
  }

  100% {
    background-size: 100% 1.5px;
  }
}

/*  Responsive label font size */
.label .s {
  font-size: 12px;
}
@media (min-width: 768px) {
  .label .s {
    font-size: 14px;
  }
}

/*  Responsive card padding

    PS! Only using .responsive-card-padding isn't a strong enough selector,
    but using div.responsive-card-padding, etc. works
*/
div.responsive-card-padding,
a.responsive-card-padding {
  padding: 24px 16px;
}
@media (min-width: 768px) {
  div.responsive-card-padding,
  a.responsive-card-padding {
    padding: min(24px, 7%) min(32px, 7%);
  }
}
@media (min-width: 1024px) {
  div.responsive-card-padding,
  a.responsive-card-padding {
    padding: min(24px, 7%) min(40px, 7%);
  }
}

/* !important to take precedence over elm-ui */
div.reverse,
div.reverse > div {
  flex-wrap: wrap-reverse !important;
}

.text-input-container input::placeholder {
  color: #767575; /* gray500 */
  font-weight: 200;
}

.text-input-container .focus-within,
.text-input-error-container .focus-within,
.ProseMirror {
  transition:
    border-color 0.1s ease-in-out,
    border-bottom-color 0.1s ease-in-out;
}

.text-input-error-container:not(:focus-within) .focus-within {
  border-bottom-color: #e64673 !important;
}

.text-input-error-container:focus-within .focus-within {
  border-bottom-color: #ef9fb5 !important; /* Fallback without alpha for Microsoft Edge */
  border-bottom-color: #e6467380 !important;
}

.text-input-container:focus-within .focus-within,
.ProseMirror:focus-within {
  border-color: inherit;
}

.text-input-container:not(:focus-within) .focus-within {
  border-bottom-color: #767575 !important; /* gray500 */
}

/* Setting this property directly on the Input.multiline element doesn't work since
   elm-ui passes it down to the textarea child, and we need it to be on the wrapper element */
.implw:has(.resize-vertical) {
  resize: vertical;
}

/* Remove number input styling in Chrome, Safari, Edge, Opera */
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Remove number input styling in Firefox */
.no-spin {
  -moz-appearance: textfield !important;
}

input[type='date']::-webkit-datetime-edit-text,
input[type='datetime-local']::-webkit-datetime-edit-text {
  padding: 0 3px;
}

/* Hide Safari only clear button from other browsers */
.clear-date-input-button {
  display: none !important;
}

/* Fix empty fields in date inputs in Safari since its rendered like this:
* If day field is empty: 03/09/2021, rather than dd/09/2021 like sane browsers renders it */
/* Hack for targeting only Safari found at https://browserstrangeness.bitbucket.io/css_hacks.html#safari */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (display: flow-root) {
    input[type='date']:not([disabled])::-webkit-datetime-edit-text,
    input[type='datetime-local']:not([disabled])::-webkit-datetime-edit-text {
      color: black;
    } /* Make the slashes always black */

    input[type='date']:not([disabled])::-webkit-datetime-edit-day-field,
    input[type='datetime-local']:not([disabled])::-webkit-datetime-edit-day-field {
      color: black;
    }
    input[type='date']:not([disabled])::-webkit-datetime-edit-month-field,
    input[type='datetime-local']:not([disabled])::-webkit-datetime-edit-month-field {
      color: black;
    }
    input[type='date']:not([disabled])::-webkit-datetime-edit-year-field,
    input[type='datetime-local']:not([disabled])::-webkit-datetime-edit-year-field {
      color: black;
    }
    input[type='date'][data-invalid-date='true'],
    input[type='datetime-local'][data-invalid-date='true'] {
      color: #eee;
    }

    .clear-date-input-button {
      display: block !important;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

input:focus {
  outline: none !important;
}

textarea:focus {
  outline: none !important;
}

*:focus {
  outline: #429cda solid !important; /* information600 */
  outline-width: 1px !important;
  outline-offset: 1px;
}

/* Use .with-box-shadow on elements that have a box-shadow and that should keep it when they are focused */
*:focus:not(.with-box-shadow):not(:has(.with-box-shadow)) * {
  box-shadow: none !important;
}

*:active:not(.with-box-shadow):not(:has(.with-box-shadow)) {
  box-shadow: none !important;
}

*:focus-within:not(.with-box-shadow):not(:has(.with-box-shadow)) {
  box-shadow: none !important;
}

.drop-shadow {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
}

.drop-shadow.clickable:hover,
.drop-shadow.clickable:focus {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.onboarding-shadow {
  box-shadow: 0px 2px 2px #e4e4e4;
}
.onboarding-shadow.clickable:hover,
.onboarding-shadow.clickable:focus {
  box-shadow: 0px 2px 2px #d8d8d8;
}

.drop-shadow-only-below {
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.15));
}

.no-focus-outline:not(.with-focus-outline):focus {
  outline: 0 !important;
}

[role='radiogroup']:focus {
  outline: none !important;
}

[role='radiogroup']:focus-within [aria-checked='true'] {
  outline: #429cda solid !important; /* information600 */
  outline-width: 1px !important;
  outline-offset: 1px;
}

.position-sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
}

/* Markdown */

.markdown {
  white-space: normal;
  word-break: break-word;
  line-height: 26px;
}

.markdown h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 22px;
  margin-bottom: 12px;
}

.markdown h1 {
  font-weight: normal;
  font-size: 26px;
  font-family: 'SangBleuRepublic';
}

.markdown h2 {
  font-weight: normal;
  font-size: 22px;
  font-family: 'SangBleuRepublic';
}

.markdown h3 {
  font-weight: bold;
  font-size: 18px;
}

.markdown h4,
h5,
h6 {
  font-weight: normal;
  font-size: 18px;
}

.markdown {
  font-size: 16px;
}

.markdown a {
  color: inherit;
}

.markdown blockquote {
  padding-left: 1em;
  border-left: 3px solid #dedede;
  margin-left: 0;
  margin-right: 0;
}

.markdown img {
  max-width: 100%;
}

/* ProseMirror */

.ProseMirror:focus {
  outline: none !important;
}

#modal .ProseMirror {
  max-height: 100%;
}

.ProseMirror {
  resize: vertical;
  flex-grow: 1;
  padding: 7px 12px;
  overflow: auto;
  border: solid #ebebeb 1px; /* gray100 */
  border-bottom-width: 2px;
  border-bottom-color: #767575; /* gray500 */
  background-color: #ffffff;
  padding: 10px 12px;
  line-height: 1.2;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* */

@media (hover: hover) {
  .hover-container:hover .hover-pencil {
    stroke: #ffffff;
  }

  .hover-container:hover .hover-circle {
    visibility: visible;
  }

  .hover-banner,
  .hover-body {
    transition: background-color 0.1s ease-in-out;
  }
  .hover-container:hover .hover-banner {
    background-color: rgb(200, 225, 220);
  }

  .hover-container:hover .hover-body {
    background-color: #f4f4f4;
  }

  .hover-container.white:hover .hover-body {
    background-color: #ffffff;
  }
}

/* Makes underlines on elm-ui's text elements work in Microsoft Edge */
.s.t {
  text-decoration: inherit;
}

/* Make centerX work on elements inside elm-ui's wrappedRow. See https://github.com/mdgriffith/elm-ui/issues/57 */
.s.r.wrp > s:first-of-type.accx {
  flex-grow: 0 !important;
  max-width: 100%;
}
.s.r.wrp > s:last-of-type.accx {
  flex-grow: 0 !important;
  max-width: 100%;
}
.cx > .wrp {
  justify-content: center !important;
}

div[role='button'] > .s,
a > .s {
  line-height: 1.2 !important;
}

select {
  margin: 0;
  font: inherit;
  font-size: 14px;
  font-weight: 400;
  color: #1a1919; /* gray1100 */
  background-color: #ffffff;
  border: solid #1a1919 1px; /* gray1100 */
  border-radius: 20px;
  padding: 10px 45px 12px 24px;
  line-height: 1.2;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%231A1919' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) center;
  background-clip: border-box;
  cursor: pointer;
}

select.button {
  font-weight: 400;
  font-size: 14px;
  color: #1a1919; /* gray1100 */
  background-color: #ffffff;
  border: solid #1a1919 1px; /* gray1100 */
  border-radius: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%231A1919' stroke-width='2'/%3E%3C/svg%3E%0A");
}

select.input {
  font-weight: 200;
  font-size: 16px;
  color: #3c3c3c; /* gray800 */
  background-color: #ffffff;
  border: solid #ebebeb 1px; /* gray100 */
  border-bottom-width: 2px;
  border-bottom-color: #767575; /* gray500 */
  border-radius: 0;
  padding: 12px 45px 14px 18px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23767575' stroke-width='2'/%3E%3C/svg%3E%0A");
}

select.simple {
  font-weight: 400;
  transition: background-color 0.2s ease;
  font-size: 10px;
  color: #3c3c3c; /* gray800 */
  border: none;
  border-radius: 4px;
  padding: 8px 28px 8px 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23767575' stroke-width='2'/%3E%3C/svg%3E%0A");
}
select.simple:hover {
  background-color: rgb(241, 241, 241);
}
@media (min-width: 768px) {
  select.simple {
    font-size: 12px;
  }
}

select option {
  color: black;
  font-weight: 400;
  font-size: 1rem;
}

.ellipsify .t {
  overflow: hidden;
  word-break: break-all;
  word-break: break-word;
  white-space: normal !important;
  display: -webkit-box !important;
  /* https://stackoverflow.com/questions/46152850/webkit-box-orient-styling-disappears-from-styling */
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: inherit;
}

:not(output):-moz-ui-invalid {
  box-shadow: none !important;
}

details summary::-webkit-details-marker {
  display: none;
}

mark {
  background: none; /* Defined in UI.mark instead */
}

/* Internet Explorer fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .s.c > .s {
    flex-basis: auto !important;
  }
}

pre {
  margin: 0;
}

/* Syntax highlighting with pablohirafuji/elm-syntax-highlight */
pre.elmsh {
  padding: 10px;
  margin: 0;
  text-align: left;
  overflow: auto;
}
code.elmsh {
  padding: 0;
}
.elmsh-line:before {
  content: attr(data-elmsh-lc);
  display: inline-block;
  text-align: right;
  width: 40px;
  padding: 0 20px 0 0;
  opacity: 0.3;
}
.elmsh {
  color: #24292e;
  background: #ffffff;
}
.elmsh-hl {
  background: #fffbdd;
}
.elmsh-add {
  background: #eaffea;
}
.elmsh-del {
  background: #ffecec;
}
.elmsh-comm {
  color: #969896;
}
.elmsh1 {
  color: #005cc5;
}
.elmsh2 {
  color: #df5000;
}
.elmsh3 {
  color: #d73a49;
}
.elmsh4 {
  color: #0086b3;
}
.elmsh5 {
  color: #63a35c;
}
.elmsh6 {
  color: #005cc5;
}
.elmsh7 {
  color: #795da3;
}

/*** elm-ui scrollbars (not the scrollbars on <body>) ***/
/* Firefox */
.sby,
.sbx {
  scrollbar-width: thin;
}
/* Chrome/Safari/Edge */
.sby::-webkit-scrollbar {
  width: 5px; /* Width of the entire scrollbar */
}
.sbx::-webkit-scrollbar {
  height: 5px; /* Height of the entire scrollbar */
}
.sby::-webkit-scrollbar-thumb,
.sbx::-webkit-scrollbar-thumb {
  background-color: rgb(193, 193, 193); /* Color of the scroll thumb */
  border-radius: 20px; /* Roundness of the scroll thumb */
}
.sby::-webkit-scrollbar-thumb:hover,
.sbx::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

/*  Make superlong words wrap in elm-ui paragraphs in Safari.
    .p already has "overflow-wrap: break-word;", but Safari doesn't seem to support this.
    This also makes superlong URLs wrap in all browsers. See https://github.com/folq/web/issues/6541
*/
.p {
  word-break: break-word;
}

.fade-to-white-right {
  -webkit-mask-image: linear-gradient(to right, rgba(255, 255, 255, 1) 94%, rgba(255, 255, 255, 0));
  -webkit-mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position:
    left top,
    left bottom;
}
@media (min-width: 768px) {
  .fade-to-white-right {
    -webkit-mask-image: linear-gradient(to right, rgba(255, 255, 255, 1) 98%, rgba(255, 255, 255, 0));
  }
}

.fade-to-white-up {
  -webkit-mask-image: linear-gradient(to top, rgba(255, 255, 255, 1) 98%, rgba(255, 255, 255, 0));
  -webkit-mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position:
    left bottom,
    right bottom;
}

#range-slider-container {
  text-align: center;
}

.range-slider-handle {
  background-color: rgb(256, 256, 256);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.75);
  margin-left: -10px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.range-slider-background-bar {
  background-color: rgb(238, 238, 238);
  height: 4px;
  width: 100%;
}

.range-slider-bar-highlight {
  background-color: rgb(92, 144, 209);
  height: 4px;
}

.range-slider-value {
  position: absolute;
  top: 0px;
  background-color: rgb(92, 144, 209);
  color: rgb(256, 256, 256);
  padding: 1px 5px;
  border-radius: 3px;
  transform: translateX(-50%);
  line-height: 1.3em;
  font-size: 13px;
  font-family:
    Open Sans,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
}

.range-slider-axis {
  position: absolute;
  bottom: 14px;
  left: 0px;
  height: 8px;
  width: 100%;
}

.range-slider-axis-label {
  position: absolute;
  bottom: 0px;
  font-size: 9px;
  transform: translateX(-50%);
  color: rgb(153, 153, 153);
}

.range-slider-tick {
  position: absolute;
  background-color: rgb(153, 153, 153);
  width: 1px;
}

.range-slider-major-tick {
  height: 8px;
}

.range-slider-minor-tick {
  height: 4px;
  margin-bottom: 4px;
}

.availability-histogram-clickable-column-wrapper:hover > .availability-histogram-clickable-column,
.availability-histogram-clickable-column-wrapper:focus > .availability-histogram-clickable-column {
  background-color: #3d8073; /* teal800 */
}

.fade-in-and-slide-up-on-mount {
  -webkit-animation: fade-in-and-slide-up 200ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fade-in-and-slide-up 200ms; /* Firefox < 16 */
  -ms-animation: fade-in-and-slide-up 200ms; /* Internet Explorer */
  -o-animation: fade-in-and-slide-up 200ms; /* Opera < 12.1 */
  animation: fade-in-and-slide-up 200ms;
}

@keyframes fade-in-and-slide-up {
  from {
    opacity: 0;
    transform: translate(0px, 12px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/* Firefox < 16 */
@-moz-keyframes fade-in-and-slide-up {
  from {
    opacity: 0;
    transform: translate(0px, 12px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fade-in-and-slide-up {
  from {
    opacity: 0;
    transform: translate(0px, 12px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/* Internet Explorer */
@-ms-keyframes fade-in-and-slide-up {
  from {
    opacity: 0;
    transform: translate(0px, 12px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/* Opera < 12.1 */
@-o-keyframes fade-in-and-slide-up {
  from {
    opacity: 0;
    transform: translate(0px, 12px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/*** Dialog ***/
dialog:not([open]) {
  display: none;
}
/* Acts as backdrop */
dialog {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 22;
  width: 100%;
  height: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0.25);
}
/* Acts as the actual dialog */
dialog .dialog-content {
  margin: 0;
  padding: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  border-radius: 10px;
  background-color: white;
  overflow: clip;
}
@media (min-width: 768px) {
  dialog .dialog-content {
    min-width: min(80vw, 720px);
    max-width: min(80vw, 820px);
  }
}

dialog .dialog-content.fullscreen {
  top: 1%;
  left: 1%;
  right: 1%;
  bottom: 1%;
  min-width: 96%;
  min-height: 96%;
  transform: none;
  overflow: scroll;
}

dialog .dialog-content:not(.fullscreen) > div {
  /* On some mobile browsers, like Safari and Chrome on iOS, the screen height is altered when the address bar is shown/hidden.
   * Take this into account when calculating max-height by reducing 100vh (which is doesn't care if the address bar is shown or hidden)
  */
  max-height: calc(100vh - 96px);
  overflow-y: auto;
}

input.switch[type='checkbox'] {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.switch {
  box-sizing: border-box;
  height: 24px;
  width: 42px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  margin: 0;
  border: 1px solid lightgray;
  background-color: #ffffff;
  transition: all 0.2s ease;
}
.switch:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: gray;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
}
.switch:checked {
  border-color: #337266; /* teal900 */
  background-color: #deedea; /* teal100 */
}
.switch:checked:after {
  transform: translateX(18px);
  background-color: #337266; /* teal900 */
}

.focus-within-outline:focus-within {
  outline: #429cda solid !important; /* information600 */
  outline-width: 1px !important;
  outline-offset: 1px;
}

input[list]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* Prevent always pointer on tooltip elements, set in balloon.min.css */
[aria-label][data-balloon-pos] {
  cursor: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nowrap,
.s.p .e.nowrap > .t {
  white-space: nowrap;
}
