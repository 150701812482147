@import "https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap";
:root {
  --balloon-border-radius: 2px;
  --balloon-color: #101010f2;
  --balloon-text-color: #fff;
  --balloon-font-size: 12px;
  --balloon-move: 4px;
}

button[aria-label][data-balloon-pos] {
  overflow: visible;
}

[aria-label][data-balloon-pos] {
  cursor: pointer;
  position: relative;
}

[aria-label][data-balloon-pos]:after {
  opacity: 0;
  pointer-events: none;
  text-indent: 0;
  text-shadow: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: var(--balloon-font-size);
  background: var(--balloon-color);
  color: var(--balloon-text-color);
  border-radius: 2px;
  border-radius: var(--balloon-border-radius);
  content: attr(aria-label);
  white-space: nowrap;
  z-index: 10;
  padding: .5em 1em;
  transition: all .18s ease-out .18s;
  position: absolute;
}

[aria-label][data-balloon-pos]:before {
  border: 5px solid #0000;
  border-top-color: var(--balloon-color);
  opacity: 0;
  pointer-events: none;
  content: "";
  z-index: 10;
  width: 0;
  height: 0;
  transition: all .18s ease-out .18s;
  position: absolute;
}

[aria-label][data-balloon-pos]:hover:before, [aria-label][data-balloon-pos]:hover:after, [aria-label][data-balloon-pos][data-balloon-visible]:before, [aria-label][data-balloon-pos][data-balloon-visible]:after, [aria-label][data-balloon-pos]:not([data-balloon-nofocus]):focus:before, [aria-label][data-balloon-pos]:not([data-balloon-nofocus]):focus:after {
  opacity: 1;
  pointer-events: none;
}

[aria-label][data-balloon-pos].font-awesome:after {
  font-family: FontAwesome, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

[aria-label][data-balloon-pos][data-balloon-break]:after {
  white-space: pre;
}

[aria-label][data-balloon-pos][data-balloon-break][data-balloon-length]:after {
  white-space: pre-line;
  word-break: break-word;
}

[aria-label][data-balloon-pos][data-balloon-blunt]:before, [aria-label][data-balloon-pos][data-balloon-blunt]:after {
  transition: none;
}

[aria-label][data-balloon-pos][data-balloon-pos="up"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos="up"][data-balloon-visible]:after, [aria-label][data-balloon-pos][data-balloon-pos="down"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos="down"][data-balloon-visible]:after, [aria-label][data-balloon-pos][data-balloon-pos="up"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos="up"][data-balloon-visible]:before, [aria-label][data-balloon-pos][data-balloon-pos="down"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos="down"][data-balloon-visible]:before {
  transform: translate(-50%);
}

[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:after {
  left: 0;
}

[aria-label][data-balloon-pos][data-balloon-pos*="-left"]:before {
  left: 5px;
}

[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:after {
  right: 0;
}

[aria-label][data-balloon-pos][data-balloon-pos*="-right"]:before {
  right: 5px;
}

[aria-label][data-balloon-pos][data-balloon-po*="-left"]:hover:after, [aria-label][data-balloon-pos][data-balloon-po*="-left"][data-balloon-visible]:after, [aria-label][data-balloon-pos][data-balloon-pos*="-right"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos*="-right"][data-balloon-visible]:after, [aria-label][data-balloon-pos][data-balloon-po*="-left"]:hover:before, [aria-label][data-balloon-pos][data-balloon-po*="-left"][data-balloon-visible]:before, [aria-label][data-balloon-pos][data-balloon-pos*="-right"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos*="-right"][data-balloon-visible]:before {
  transform: translate(0);
}

[aria-label][data-balloon-pos][data-balloon-pos^="up"]:before, [aria-label][data-balloon-pos][data-balloon-pos^="up"]:after {
  transform-origin: top;
  transform: translate(0, var(--balloon-move));
  bottom: 100%;
}

[aria-label][data-balloon-pos][data-balloon-pos^="up"]:after {
  margin-bottom: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos="up"]:before, [aria-label][data-balloon-pos][data-balloon-pos="up"]:after {
  transform: translate(-50%, var(--balloon-move));
  left: 50%;
}

[aria-label][data-balloon-pos][data-balloon-pos^="down"]:before, [aria-label][data-balloon-pos][data-balloon-pos^="down"]:after {
  transform: translate(0, calc(var(--balloon-move) * -1));
  top: 100%;
}

[aria-label][data-balloon-pos][data-balloon-pos^="down"]:after {
  margin-top: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos^="down"]:before {
  border: 5px solid #0000;
  border-bottom-color: var(--balloon-color);
  width: 0;
  height: 0;
}

[aria-label][data-balloon-pos][data-balloon-pos="down"]:after, [aria-label][data-balloon-pos][data-balloon-pos="down"]:before {
  transform: translate(-50%, calc(var(--balloon-move) * -1));
  left: 50%;
}

[aria-label][data-balloon-pos][data-balloon-pos="left"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos="left"][data-balloon-visible]:after, [aria-label][data-balloon-pos][data-balloon-pos="right"]:hover:after, [aria-label][data-balloon-pos][data-balloon-pos="right"][data-balloon-visible]:after, [aria-label][data-balloon-pos][data-balloon-pos="left"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos="left"][data-balloon-visible]:before, [aria-label][data-balloon-pos][data-balloon-pos="right"]:hover:before, [aria-label][data-balloon-pos][data-balloon-pos="right"][data-balloon-visible]:before {
  transform: translate(0, -50%);
}

[aria-label][data-balloon-pos][data-balloon-pos="left"]:after, [aria-label][data-balloon-pos][data-balloon-pos="left"]:before {
  transform: translate(var(--balloon-move), -50%);
  top: 50%;
  right: 100%;
}

[aria-label][data-balloon-pos][data-balloon-pos="left"]:after {
  margin-right: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos="left"]:before {
  border: 5px solid #0000;
  border-left-color: var(--balloon-color);
  width: 0;
  height: 0;
}

[aria-label][data-balloon-pos][data-balloon-pos="right"]:after, [aria-label][data-balloon-pos][data-balloon-pos="right"]:before {
  transform: translate(calc(var(--balloon-move) * -1), -50%);
  top: 50%;
  left: 100%;
}

[aria-label][data-balloon-pos][data-balloon-pos="right"]:after {
  margin-left: 10px;
}

[aria-label][data-balloon-pos][data-balloon-pos="right"]:before {
  border: 5px solid #0000;
  border-right-color: var(--balloon-color);
  width: 0;
  height: 0;
}

[aria-label][data-balloon-pos][data-balloon-length]:after {
  white-space: normal;
}

[aria-label][data-balloon-pos][data-balloon-length="small"]:after {
  width: 80px;
}

[aria-label][data-balloon-pos][data-balloon-length="medium"]:after {
  width: 150px;
}

[aria-label][data-balloon-pos][data-balloon-length="large"]:after {
  width: 260px;
}

[aria-label][data-balloon-pos][data-balloon-length="xlarge"]:after {
  width: 380px;
}

@media screen and (width <= 768px) {
  [aria-label][data-balloon-pos][data-balloon-length="xlarge"]:after {
    width: 90vw;
  }
}

[aria-label][data-balloon-pos][data-balloon-length="fit"]:after {
  width: 100%;
}

html.hidden-overflow {
  overflow: hidden;
}

body {
  hyphens: auto;
  -webkit-hyphenate-character: "-";
}

body:has(dialog.dialog[role="dialog"][open]) {
  overflow: hidden;
}

::selection {
  color: #1a1919;
  background: #deedea;
}

#elm-isolation, #elm-isolation > div, #elm-isolation > div > div, #elm {
  height: 100%;
}

@font-face {
  font-family: SangBleuRepublic;
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url("SangBleuRepublic-Book.e32ec1dc.otf") format("opentype");
}

@font-face {
  font-family: SangBleuRepublic;
  font-display: swap;
  font-style: italic;
  font-weight: normal;
  src: url("SangBleuRepublic-BookItalic.ce0dccc7.otf") format("opentype");
}

@font-face {
  font-family: SangBleuRepublic;
  font-display: swap;
  font-style: normal;
  font-weight: 450;
  src: url("SangBleuRepublic-Regular.15f01457.otf") format("opentype");
}

@font-face {
  font-family: SangBleuRepublic;
  font-display: swap;
  font-style: italic;
  font-weight: 450;
  src: url("SangBleuRepublic-RegularItalic.59ef9cc2.otf") format("opentype");
}

@font-face {
  font-family: SangBleuRepublic;
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("SangBleuRepublic-Medium.8165e7ae.otf") format("opentype");
}

@font-face {
  font-family: SangBleuRepublic;
  font-display: swap;
  font-style: italic;
  font-weight: 500;
  src: url("SangBleuRepublic-MediumItalic.636d1442.otf") format("opentype");
}

@font-face {
  font-family: SangBleuRepublic;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("SangBleuRepublic-Bold.189a89a4.otf") format("opentype");
}

@font-face {
  font-family: SangBleuRepublic;
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: url("SangBleuRepublic-BoldItalic.7e9582a2.otf") format("opentype");
}

h1.hero, a.hero {
  letter-spacing: -.008em;
  font-size: 40px;
}

h1.h1, a.h1 {
  letter-spacing: -.008em;
  font-size: 32px;
}

h1.t1, a.t1 {
  font-size: 32px;
}

h2.h2, a.h2 {
  letter-spacing: -.008em;
  font-size: 28px;
}

h2.t2, a.t2 {
  font-size: 28px;
}

h3.h3, a.h3, h3.t3, a.t3 {
  font-size: 22px;
}

h4.h4, a.h4, h4.t4, a.t4 {
  font-size: 20px;
}

h5.h5, a.h5 {
  font-size: 18px;
}

h6.h6, a.h6 {
  font-size: 16px;
}

@media (width >= 768px) {
  h1.hero, a.hero {
    font-size: 48px;
  }

  h1.h1, a.h1, h1.t1, a.t1 {
    font-size: 34px;
  }

  h2.h2, a.h2, h2.t2, a.t2 {
    font-size: 30px;
  }

  h3.h3, a.h3, h3.t3, a.t3 {
    font-size: 24px;
  }
}

@media (width >= 1024px) {
  h1.hero, a.hero {
    font-size: 57px;
  }

  h1.h1, a.h1, h1.t1, a.t1 {
    font-size: 36px;
  }

  h2.h2, a.h2, h2.t2, a.t2 {
    font-size: 32px;
  }

  h3.h3, a.h3, h3.t3, a.t3 {
    font-size: 26px;
  }
}

.ingress-large {
  font-size: 18px !important;
}

.ingress-medium {
  font-size: 16px !important;
}

.ingress-small {
  font-size: 14px !important;
}

@media (width >= 768px) {
  .ingress-large {
    font-size: 20px !important;
  }

  .ingress-medium {
    font-size: 18px !important;
  }

  .ingress-small {
    font-size: 16px !important;
  }
}

a.underline-animation:not(.no-underline-animation) .t, [role="button"].underline-animation:not(.no-underline-animation) .t, .markdown a {
  background-image: linear-gradient(#0000, #0000), linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1.5px;
  border-bottom-width: 0;
  text-decoration: none;
}

a.underline-animation:not(.no-underline-animation):hover .t, [role="button"].underline-animation:not(.no-underline-animation):hover .t, .markdown a:hover, a.underline-animation:not(.no-underline-animation):focus .t, [role="button"].underline-animation:not(.no-underline-animation):focus .t, .markdown a:focus {
  animation-name: link-underline-animation;
  animation-duration: .4s;
  animation-fill-mode: forwards;
}

a.hide-initial-link-underline:not([aria-selected="true"]) .t, .hide-initial-link-underline a.underline-animation:not([aria-selected="true"]) .t, .hide-initial-link-underline [role="button"].underline-animation:not([aria-selected="true"]) .t {
  background-size: 0 1.5px;
}

.no-underline-animation .t {
  background-size: 0 1.5px !important;
  animation-name: none !important;
}

@keyframes link-underline-animation {
  0% {
    background-size: 0% 1.5px;
  }

  100% {
    background-size: 100% 1.5px;
  }
}

.label .s {
  font-size: 12px;
}

@media (width >= 768px) {
  .label .s {
    font-size: 14px;
  }
}

div.responsive-card-padding, a.responsive-card-padding {
  padding: 24px 16px;
}

@media (width >= 768px) {
  div.responsive-card-padding, a.responsive-card-padding {
    padding: min(24px, 7%) min(32px, 7%);
  }
}

@media (width >= 1024px) {
  div.responsive-card-padding, a.responsive-card-padding {
    padding: min(24px, 7%) min(40px, 7%);
  }
}

div.reverse, div.reverse > div {
  flex-wrap: wrap-reverse !important;
}

.text-input-container input::placeholder {
  color: #767575;
  font-weight: 200;
}

.text-input-container .focus-within, .text-input-error-container .focus-within, .ProseMirror {
  transition: border-color .1s ease-in-out, border-bottom-color .1s ease-in-out;
}

.text-input-error-container:not(:focus-within) .focus-within {
  border-bottom-color: #e64673 !important;
}

.text-input-error-container:focus-within .focus-within {
  border-bottom-color: #e6467380 !important;
}

.text-input-container:focus-within .focus-within, .ProseMirror:focus-within {
  border-color: inherit;
}

.text-input-container:not(:focus-within) .focus-within {
  border-bottom-color: #767575 !important;
}

.implw:has(.resize-vertical) {
  resize: vertical;
}

.no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance: textfield !important;
}

input[type="date"]::-webkit-datetime-edit-text {
  padding: 0 3px;
}

input[type="datetime-local"]::-webkit-datetime-edit-text {
  padding: 0 3px;
}

.clear-date-input-button {
  display: none !important;
}

@media not all and (resolution >= .001dpcm) {
  @supports ((-webkit-appearance: none)) and (display: flow-root) {
    input[type="date"]:not([disabled])::-webkit-datetime-edit-text {
      color: #000;
    }

    input[type="datetime-local"]:not([disabled])::-webkit-datetime-edit-text {
      color: #000;
    }

    input[type="date"]:not([disabled])::-webkit-datetime-edit-day-field {
      color: #000;
    }

    input[type="datetime-local"]:not([disabled])::-webkit-datetime-edit-day-field {
      color: #000;
    }

    input[type="date"]:not([disabled])::-webkit-datetime-edit-month-field {
      color: #000;
    }

    input[type="datetime-local"]:not([disabled])::-webkit-datetime-edit-month-field {
      color: #000;
    }

    input[type="date"]:not([disabled])::-webkit-datetime-edit-year-field {
      color: #000;
    }

    input[type="datetime-local"]:not([disabled])::-webkit-datetime-edit-year-field {
      color: #000;
    }

    input[type="date"][data-invalid-date="true"], input[type="datetime-local"][data-invalid-date="true"] {
      color: #eee;
    }

    .clear-date-input-button {
      display: block !important;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}

input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input:focus, textarea:focus {
  outline: none !important;
}

:focus {
  outline-offset: 1px;
  outline: 1px solid #429cda !important;
}

:focus:not(.with-box-shadow):not(:has(.with-box-shadow)) *, :active:not(.with-box-shadow):not(:has(.with-box-shadow)), :focus-within:not(.with-box-shadow):not(:has(.with-box-shadow)) {
  box-shadow: none !important;
}

.drop-shadow {
  box-shadow: 0 2px 2px #0000000d;
}

.drop-shadow.clickable:hover, .drop-shadow.clickable:focus {
  box-shadow: 0 2px 2px #0000001a;
}

.onboarding-shadow {
  box-shadow: 0 2px 2px #e4e4e4;
}

.onboarding-shadow.clickable:hover, .onboarding-shadow.clickable:focus {
  box-shadow: 0 2px 2px #d8d8d8;
}

.drop-shadow-only-below {
  filter: drop-shadow(0 2px 1px #00000026);
}

.no-focus-outline:not(.with-focus-outline):focus {
  outline: 0 !important;
}

[role="radiogroup"]:focus {
  outline: none !important;
}

[role="radiogroup"]:focus-within [aria-checked="true"] {
  outline-offset: 1px;
  outline: 1px solid #429cda !important;
}

.position-sticky {
  position: sticky !important;
}

.markdown {
  white-space: normal;
  word-break: break-word;
  line-height: 26px;
}

.markdown h1, h2, h3, h4, h5, h6 {
  margin-top: 22px;
  margin-bottom: 12px;
}

.markdown h1 {
  font-family: SangBleuRepublic;
  font-size: 26px;
  font-weight: normal;
}

.markdown h2 {
  font-family: SangBleuRepublic;
  font-size: 22px;
  font-weight: normal;
}

.markdown h3 {
  font-size: 18px;
  font-weight: bold;
}

.markdown h4, h5, h6 {
  font-size: 18px;
  font-weight: normal;
}

.markdown {
  font-size: 16px;
}

.markdown a {
  color: inherit;
}

.markdown blockquote {
  border-left: 3px solid #dedede;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1em;
}

.markdown img {
  max-width: 100%;
}

.ProseMirror:focus {
  outline: none !important;
}

#modal .ProseMirror {
  max-height: 100%;
}

.ProseMirror {
  resize: vertical;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-bottom: 2px solid #767575;
  flex-grow: 1;
  padding: 10px 12px;
  line-height: 1.2;
  overflow: auto;
}

@media (hover: hover) {
  .hover-container:hover .hover-pencil {
    stroke: #fff;
  }

  .hover-container:hover .hover-circle {
    visibility: visible;
  }

  .hover-banner, .hover-body {
    transition: background-color .1s ease-in-out;
  }

  .hover-container:hover .hover-banner {
    background-color: #c8e1dc;
  }

  .hover-container:hover .hover-body {
    background-color: #f4f4f4;
  }

  .hover-container.white:hover .hover-body {
    background-color: #fff;
  }
}

.s.t {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.s.r.wrp > s:first-of-type.accx, .s.r.wrp > s:last-of-type.accx {
  max-width: 100%;
  flex-grow: 0 !important;
}

.cx > .wrp {
  justify-content: center !important;
}

div[role="button"] > .s, a > .s {
  line-height: 1.2 !important;
}

select {
  font: inherit;
  color: #1a1919;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%231A1919' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: calc(100% - 14px);
  background-repeat: no-repeat;
  background-clip: border-box;
  border: 1px solid #1a1919;
  border-radius: 20px;
  margin: 0;
  padding: 10px 45px 12px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

select.button {
  color: #1a1919;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%231A1919' stroke-width='2'/%3E%3C/svg%3E%0A");
  border: 1px solid #1a1919;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
}

select.input {
  color: #3c3c3c;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23767575' stroke-width='2'/%3E%3C/svg%3E%0A");
  border: 1px solid #ebebeb;
  border-bottom: 2px solid #767575;
  border-radius: 0;
  padding: 12px 45px 14px 18px;
  font-size: 16px;
  font-weight: 200;
}

select.simple {
  color: #3c3c3c;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23767575' stroke-width='2'/%3E%3C/svg%3E%0A");
  border: none;
  border-radius: 4px;
  padding: 8px 28px 8px 8px;
  font-size: 10px;
  font-weight: 400;
  transition: background-color .2s;
}

select.simple:hover {
  background-color: #f1f1f1;
}

@media (width >= 768px) {
  select.simple {
    font-size: 12px;
  }
}

select option {
  color: #000;
  font-size: 1rem;
  font-weight: 400;
}

.ellipsify .t {
  word-break: break-all;
  word-break: break-word;
  -webkit-line-clamp: 1;
  line-height: inherit;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal !important;
  display: -webkit-box !important;
}

:not(output):-moz-ui-invalid {
  box-shadow: none !important;
}

details summary::-webkit-details-marker {
  display: none;
}

mark {
  background: none;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .s.c > .s {
    flex-basis: auto !important;
  }
}

pre {
  margin: 0;
}

pre.elmsh {
  text-align: left;
  margin: 0;
  padding: 10px;
  overflow: auto;
}

code.elmsh {
  padding: 0;
}

.elmsh-line:before {
  content: attr(data-elmsh-lc);
  text-align: right;
  opacity: .3;
  width: 40px;
  padding: 0 20px 0 0;
  display: inline-block;
}

.elmsh {
  color: #24292e;
  background: #fff;
}

.elmsh-hl {
  background: #fffbdd;
}

.elmsh-add {
  background: #eaffea;
}

.elmsh-del {
  background: #ffecec;
}

.elmsh-comm {
  color: #969896;
}

.elmsh1 {
  color: #005cc5;
}

.elmsh2 {
  color: #df5000;
}

.elmsh3 {
  color: #d73a49;
}

.elmsh4 {
  color: #0086b3;
}

.elmsh5 {
  color: #63a35c;
}

.elmsh6 {
  color: #005cc5;
}

.elmsh7 {
  color: #795da3;
}

.sby, .sbx {
  scrollbar-width: thin;
}

.sby::-webkit-scrollbar {
  width: 5px;
}

.sbx::-webkit-scrollbar {
  height: 5px;
}

.sby::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 20px;
}

.sbx::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 20px;
}

.sby::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.sbx::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

.p {
  word-break: break-word;
}

.fade-to-white-right {
  -webkit-mask-image: linear-gradient(to right, #fff 94%, #fff0);
  -webkit-mask-position: 0 0, 0 100%;
  -webkit-mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
}

@media (width >= 768px) {
  .fade-to-white-right {
    -webkit-mask-image: linear-gradient(to right, #fff 98%, #fff0);
  }
}

.fade-to-white-up {
  -webkit-mask-image: linear-gradient(to top, #fff 98%, #fff0);
  -webkit-mask-position: 0 100%, 100% 100%;
  -webkit-mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
}

#range-slider-container {
  text-align: center;
}

.range-slider-handle {
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  box-shadow: 0 1px 5px #000000bf;
}

.range-slider-background-bar {
  background-color: #eee;
  width: 100%;
  height: 4px;
}

.range-slider-bar-highlight {
  background-color: #5c90d1;
  height: 4px;
}

.range-slider-value {
  color: #fff;
  background-color: #5c90d1;
  border-radius: 3px;
  padding: 1px 5px;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.3em;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.range-slider-axis {
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 14px;
  left: 0;
}

.range-slider-axis-label {
  color: #999;
  font-size: 9px;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
}

.range-slider-tick {
  background-color: #999;
  width: 1px;
  position: absolute;
}

.range-slider-major-tick {
  height: 8px;
}

.range-slider-minor-tick {
  height: 4px;
  margin-bottom: 4px;
}

.availability-histogram-clickable-column-wrapper:hover > .availability-histogram-clickable-column, .availability-histogram-clickable-column-wrapper:focus > .availability-histogram-clickable-column {
  background-color: #3d8073;
}

.fade-in-and-slide-up-on-mount {
  -ms-animation: fade-in-and-slide-up .2s;
  animation: .2s fade-in-and-slide-up;
}

@keyframes fade-in-and-slide-up {
  from {
    opacity: 0;
    transform: translate(0, 12px);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
}

dialog:not([open]) {
  display: none;
}

dialog {
  z-index: 22;
  background-color: #00000040;
  border: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  inset: 0;
}

dialog .dialog-content {
  background-color: #fff;
  border-radius: 10px;
  min-width: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: clip;
  transform: translate(-50%, -50%);
}

@media (width >= 768px) {
  dialog .dialog-content {
    min-width: min(80vw, 720px);
    max-width: min(80vw, 820px);
  }
}

dialog .dialog-content.fullscreen {
  min-width: 96%;
  min-height: 96%;
  inset: 1%;
  overflow: scroll;
  transform: none;
}

dialog .dialog-content:not(.fullscreen) > div {
  max-height: calc(100vh - 96px);
  overflow-y: auto;
}

input.switch[type="checkbox"] {
  box-sizing: border-box;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.switch {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 16px;
  width: 42px;
  height: 24px;
  margin: 0;
  transition: all .2s;
  display: inline-block;
  position: relative;
}

.switch:after {
  content: "";
  background-color: gray;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transition: all .2s cubic-bezier(.5, .1, .75, 1.35);
  position: absolute;
  top: 3px;
  left: 3px;
  box-shadow: 0 1px 2px #0003;
}

.switch:checked {
  background-color: #deedea;
  border-color: #337266;
}

.switch:checked:after {
  background-color: #337266;
  transform: translateX(18px);
}

.focus-within-outline:focus-within {
  outline-offset: 1px;
  outline: 1px solid #429cda !important;
}

input[list]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

[aria-label][data-balloon-pos] {
  cursor: inherit;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.nowrap, .s.p .e.nowrap > .t {
  white-space: nowrap;
}
/*# sourceMappingURL=public.e5333eaf.css.map */
